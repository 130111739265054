const nextSeoConfig = {
  titleTemplate: "Glock Foundation | %s",
  defaultTitle: "Glock Foundation",
  description:
    "The Glock Foundation is a non-profit organization to raise funds to support breast cancer research, clinical trials, education, and patient care.",
  openGraph: {
    type: "website",
    url: "https://www.glockfoundation.org/",
    title:
      "Glock Foundation - Raising Funds for Breast Cancer Research, Clinical Trials, Education, and Patient Care",
    description:
      "The Glock Foundation is a non-profit organization to raise funds to support breast cancer research, clinical trials, education, and patient care.",
    images: [{ url: "https://www.glockfoundation.org/fb-logo.jpg" }],
  },
  twitter: {
    handle: "@glockfoundation",
    site: "@glockfoundation",
    cardType: "summary_large_image",
  },
};

export default nextSeoConfig;
